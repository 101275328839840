import { parseHashTag } from 'utils';

import { RegexMatchResult, TriggerData } from './Post.interfaces';

import { PostGraphType } from '../../../services';

export const updateFeedOnPostRelate = (posts: PostGraphType[], hasRelated: boolean, postId: number) => {
  const updatedFeed = posts.map((post: PostGraphType) => {
    if (post.id === postId) {
      return {
        ...post,
        hasRelated,
        relateCount: hasRelated ? Number(post.relateCount) + 1 : Number(post.relateCount) - 1,
      };
    }

    return post;
  });

  return updatedFeed;
};

export const updateFeedOnVotePoll = (posts: PostGraphType[], userId: number, postId: number, itemId: number) => {
  const updatedFeed = posts.map((post: PostGraphType) => {
    if (post.id === postId) {
      return {
        ...post,
        poll: {
          ...post.poll,
          userVote: {
            pollItemId: itemId,
          },
          items: post.poll?.items?.map((item) => {
            if (item.id === itemId) {
              return {
                ...item,
                votes: [...item.votes, { pollItemId: itemId, userId }],
                votesCount: item.votesCount + 1,
              };
            }

            return item;
          }),
        },
      };
    }
    return post;
  }) as PostGraphType[];

  return updatedFeed;
};

export const addHashTagMarkupToMessage = (message: string) => {
  return message
    .split(' ')
    .map((word) =>
      word.startsWith('#') && word.length >= 2
        ? `<span class="c-post-message--highlight"><a href="/social/feed?hashtag=${parseHashTag(word)}">${word}</a></span>`
        : word,
    )
    .join(' ');
};

const getMentionDataFromRegExMatchResult = ([, original, trigger, name, id]: RegexMatchResult): TriggerData => ({
  original,
  trigger,
  name,
  id,
});

const getMentionDataFromRegExMatch = (matchPlainText: string): TriggerData | null => {
  const triggerRegEx = /({([^{^}]*)}\[([^[]*)]\(([^(^)]*)\))/i;

  const regexExecResult = triggerRegEx.exec(matchPlainText) as RegexMatchResult | null;
  return regexExecResult ? getMentionDataFromRegExMatchResult(regexExecResult) : null;
};

const addMentionsMarkupToMessage = (message: string) => {
  let updatedMessage = message;

  const singleGroupTriggerRegEx = /({[^{^}]*}\[[^[]*]\([^(^)]*\))/gi;
  const dividedValueByRegex = message.split(singleGroupTriggerRegEx);
  dividedValueByRegex.forEach((text) => {
    if (getMentionDataFromRegExMatch(text) === null) return;

    const mentionData = getMentionDataFromRegExMatch(text) as TriggerData;
    const href = `/social/profile/${mentionData.id}`;
    updatedMessage = updatedMessage.replace(mentionData.original, `<a class="c-post__mention" href='${href}'>@${mentionData.name}</a>`);
  });

  return updatedMessage;
};

export const getMessageMarkup = (message: string) => {
  const messageWithHashtags = addHashTagMarkupToMessage(message);
  const messageWithMentions = addMentionsMarkupToMessage(messageWithHashtags);

  return messageWithMentions;
};
