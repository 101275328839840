import { useEffect, useState } from 'react';

import { ContentPageProvider, useGoogleAnalytics } from '@netfront/ekardo-content-library';
import { useUser } from '@netfront/gelada-identity-library';
import { Icons } from '@netfront/ui-library';
import { PageView } from 'components';
import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Toaster } from 'react-hot-toast';

import ErrorBoundary from '../components/Social/ErrorBoundary/ErrorBoundary';
import { SocialContextProvider } from '../contexts';
import { SessionProvider } from 'contexts/sessionProvider';

import '@egjs/flicking-plugins/dist/arrow.min.css';
import '@egjs/flicking-plugins/dist/pagination.min.css';
import '@egjs/react-flicking/dist/flicking.css';

import '@netfront/ui-library/dist/esm/css/index.css';
import '@netfront/ui-library/dist/css/global.css';
import '@netfront/ui-library/dist/css/helpers.css';
import 'react-circular-progressbar/dist/styles.css';

import '../styles/cms.css';
import '../styles/cms/base.css';
import '../styles/cms/accordion.css';
import '../styles/cms/calendar.css';
import '../styles/cms/checkbox.css';
import '../styles/cms/custom.css';
import '../styles/cms/print.css';
import '../styles/cms/range.css';
import '../styles/cms/section.css';
import '../styles/cms/snippet.css';
import '../styles/cms/radio.css';
import '../styles/cms/table.css';
import '../styles/cms/toggle-switch.css';
import '../styles/components.css';
import '../styles/globals.css';
import '../styles/theme.css';
import '../styles/utils.css';

function App({ Component, pageProps }: AppProps) {
  const googleAnalyticsMeasurementId = String(process.env.REACT_APP_GOOGLE_ANALYTICS);
  const isGoogleAnalyticsDebugMode = String(process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG_MODE).toUpperCase() === 'Y';

  const { trackPageView, canRunAuthenticated } = useGoogleAnalytics();
  const { events } = useRouter();
  const { getUser } = useUser();

  const user = getUser();

  const [gtagConfigParams, setGtagConfigParams] = useState<ReturnType<typeof omitBy>>();

  useEffect(() => {
    if (canRunAuthenticated || !user) {
      trackPageView(window.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canRunAuthenticated]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (canRunAuthenticated || !user) {
        trackPageView(window.location.pathname);
      }
    };

    events.on('routeChangeComplete', handleRouteChange);
  }, []);

  useEffect(() => {
    if (gtagConfigParams || !user) {
      return;
    }

    setGtagConfigParams(() =>
      omitBy(
        {
          debug_mode: isGoogleAnalyticsDebugMode ? true : undefined,
          send_page_view: false,
          user_id: String(user.id),
        },
        isNil,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gtagConfigParams, user]);

  useEffect(() => {
    // unexpected build error when removing unused PageView component
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleRouteChange = (url: string) => {
      PageView(url, Number(user?.id));
    };

  }, [events, user]);

  const gtagConfig = gtagConfigParams && Object.keys(gtagConfigParams).length ? `, ${JSON.stringify(gtagConfigParams)}` : '';

  return (
    <div id="root">
      <Head>
        {/* Global Site Tag (gtag.js) - Google Analytics */}
        <script src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsMeasurementId}`} async />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            ${gtagConfig ? `gtag('config', '${googleAnalyticsMeasurementId}'${gtagConfig});` : ''}
          `,
          }}
        />
      </Head>
      <div>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <Icons />
      <SessionProvider>
        <SocialContextProvider>
          <ContentPageProvider>
            {
              <ErrorBoundary>
                {/* // eslint-disable-next-line react/jsx-props-no-spreading */}
                <Component {...pageProps} />
              </ErrorBoundary>
            }
          </ContentPageProvider>
        </SocialContextProvider>
      </SessionProvider>
    </div>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
