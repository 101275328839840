export const getValidClassNames = (object: { [key: string]: boolean }) => {
  return Object.keys(object)
    .filter((key) => object[key])
    .join(' ');
};

export const formatDate = (date: Date) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [day, monthName, dateNumber] = new Date(date).toDateString().split(' ');
  return `${dateNumber} ${monthName}`;
};

export const formatTime = (date: Date) => {
  return `${new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
};

export const shouldTrackAnalytics = (): boolean => {
  return Boolean(process.env.REACT_APP_GOOGLE_ANALYTICS)
};

export const extractHashtags = (m: string): string[] => {
  const hashtagRegex = /#\w+/g;
  return (m.match(hashtagRegex) ?? []).map((hashtag: string) => hashtag.slice(1));
};

export const parseHashTag = (hashtag: string) => hashtag.includes('#') ? hashtag.split('#')[1] : hashtag;