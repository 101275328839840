import { useState } from 'react';

import { useDomain, useLogout } from '@netfront/gelada-identity-library';
import { Avatar, Dropdown } from '@netfront/ui-library';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { OnlineStatus } from '../OnlineStatus';

import { USER_MENU_DATA } from './UserMenu.constants';
import { UserMenuProps } from './UserMenu.interfaces';

import { useToast } from '../../../hooks';

const UserMenu = ({ avatar, displayName, email, isOnline = false }: UserMenuProps) => {
  const { push } = useRouter();
  const { getDomain, isDomainReady } = useDomain();
  const { handleToastError } = useToast();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onDisplayContent = (isDisplay: boolean) => setIsOpen(isDisplay);

  const { handleLogout } = useLogout({
    onCompleted: () => {
      push('/').catch((error) =>
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        }),
      );
    },
  });

  return (
    <>
      <Dropdown
        additionalClassNames="c-user-menu"
        dropdownId="avatar-menu"
        isDisplayContent={isOpen}
        trigger={
          <>
            {isOnline && <OnlineStatus size="sm" />}
            <Avatar {...(avatar && { image: <img alt={displayName} src={avatar} /> })} size="small" title={displayName} />
          </>
        }
        onDisplayContent={onDisplayContent}
      >
        <ul className="c-user-menu__dropdown">
          <li className="c-user-menu__item">
            <div className="c-user-menu__header">
              <p className="c-user-menu__title">{displayName}</p>
              <p className="c-user-menu__description">{email}</p>
            </div>
          </li>
          {USER_MENU_DATA.map(({ href, id, label }) => (
            <li key={id} className="c-user-menu__item">
              <NextLink href={href} legacyBehavior>
                <a className="c-user-menu__link">{label}</a>
              </NextLink>
            </li>
          ))}

          <li className="c-user-menu__item">
            <NextLink href="/" legacyBehavior>
              <button
                className="c-user-menu__link c-user-menu__logout"
                disabled={!isDomainReady}
                onClick={() => handleLogout({ domain: getDomain(), path: '/' })}
              >
                Logout
              </button>
            </NextLink>
          </li>
        </ul>
      </Dropdown>
    </>
  );
};

export { UserMenu };
