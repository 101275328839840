import { useEffect, useState } from "react";

import { ToggleSwitch } from "@netfront/ui-library";
import { useToast, useUpdateUserSettings } from "hooks";
import { EUserSettings } from "services";

import { NotificationSettingsProps } from "./NotificationSettings.interfaces";

export const NotificationSettings = ({ user }: NotificationSettingsProps) => {
  const { handleToastSuccess } = useToast();

  const [notificationSettings, setNotificationSettings] = useState<Array<EUserSettings>>([]);
  const [canSave, setCanSave] = useState<boolean>(false);

  const toggleNotificationSettings = (setting: EUserSettings) => {
    if (notificationSettings.includes(setting)) {
      setNotificationSettings(notificationSettings.filter((r) => r !== setting));
    } else {
      setNotificationSettings([...notificationSettings, setting]);
    }

    setCanSave(true);
  };
  
  const { handleUpdateUserSettings } = useUpdateUserSettings({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Your notification settings have been updated',
      });
    },
  });
  
  useEffect(() => {
    if (!user) {
      return;
    }
    setNotificationSettings(user.settings ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if(!canSave) return;
    
    handleUpdateUserSettings({ 
      settings: notificationSettings 
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSave, notificationSettings])
  
  
  return(
    <div className="bg-white p-5 py-6 mb-6 rounded flex flex-col gap-4">
      <ToggleSwitch
        additionalClassNames="c-toggle-switch--inline"
        id="notification-switch-commented-post"
        isChecked={notificationSettings.includes(EUserSettings.CommentedPost)}
        labelText="Receive an email when someone comments on your post."
        onChange={() => toggleNotificationSettings(EUserSettings.CommentedPost)}
      />
      <ToggleSwitch
        additionalClassNames="c-toggle-switch--inline"
        id="notification-switch-message-received"
        isChecked={notificationSettings.includes(EUserSettings.MessageReceive)}
        labelText="Receive an email when you receive a new message."
        onChange={() => toggleNotificationSettings(EUserSettings.MessageReceive)}
      />
      <ToggleSwitch
        additionalClassNames="c-toggle-switch--inline"
        id="notification-switch-commented-comment"
        isChecked={notificationSettings.includes(EUserSettings.CommentedComment)}
        labelText="Receive an email when someone comments on your comment."
        onChange={() => toggleNotificationSettings(EUserSettings.CommentedComment)}
      />
      <ToggleSwitch
        additionalClassNames="c-toggle-switch--inline"
        id="notification-switch-liked-comment"
        isChecked={notificationSettings.includes(EUserSettings.LikedComment)}
        labelText="Receive an email when someone likes your comment."
        onChange={() => toggleNotificationSettings(EUserSettings.LikedComment)}
      />
      <ToggleSwitch
        additionalClassNames="c-toggle-switch--inline"
        id="notification-switch-liked-wall-post"
        isChecked={notificationSettings.includes(EUserSettings.LikedWallPost)}
        labelText="Receive an email when someone likes your post."
        onChange={() => toggleNotificationSettings(EUserSettings.LikedWallPost)}
      />
    </div>
  )
};