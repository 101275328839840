import { ChangeEvent, useEffect, useState } from 'react';

import { useToggle } from '@netfront/common-library';
import { CalendarSnippet, useGoogleAnalytics } from '@netfront/ekardo-content-library';
import { Button, CheckboxGroup, Dialog, DropzoneFileUpload, Input, ToggleSwitch } from '@netfront/ui-library';
import { useSocialContext } from 'contexts';
import { DBCommunity, DBPost, ESocialAnalyticEvents } from 'interfaces';
import isEmpty from 'lodash.isempty';
import { useRouter } from 'next/router';
import { extractHashtags, getValidClassNames, shouldTrackAnalytics } from 'utils';
import uuid4 from 'uuid4';

import { PostMessageEditor } from 'components/Social/PostMessageEditor';

import {
  BaseLayoutPage,
  IconEmotion,
  IconPoll,
  IconImage,
  IconCross,
  PostFeelingsDialog,
  PostFeeling,
  ImagePreview,
  BASE_POLL_ITEMS,
  POLL_ITEM_MINIMUM_ERROR_MESSAGE,
  IconWarning,
  POLL_TITLE_ERROR_MESSAGE,
  IPollitem,
} from '../../../../components/Social';
import { useToast } from '../../../../hooks';
import {
  CreatePostAssetMutation,
  CreatePostMutation,
  EAssetType,
  ECommunityStatus,
  EPostFeeling,
  EPostType,
  EShareOption,
  GetUserCommunitiesQueryResult,
  useCreatePoll,
  useCreatePost,
  useCreatePostAsset,
  useDeleteAsset,
  useEditPost,
  useGetCommunitiesByUser,
  useGetPost,
  useUpdatePoll,
} from '../../../../services';

const CreatePostPage = () => {
  const {
    query: { edit },
  } = useRouter();
  const { push } = useRouter();
  const { handleToastError } = useToast();
  const { trackEvent } = useGoogleAnalytics();

  const isEditModeRequested = Boolean(edit);

  const { user } = useSocialContext();
  const { isToggled: isFeelingsDialogOpen, toggle: toggleFeelingsDialog } = useToggle();
  const { isToggled: isImageDialogOpen, toggle: toggleImageDialog } = useToggle();
  const { isToggled: isShareDialogOpen, toggle: toggleShareDialog } = useToggle();
  const { isToggled: isDeleteAssetDialogOpen, toggle: toggleDeleteAssetDialog } = useToggle();

  const [message, setMessage] = useState<string>('');
  const [isPollVisible, setIsPollVisible] = useState<boolean>(false);
  const [pollTitle, setPollTitle] = useState<string>('');
  const [pollItems, setPollItems] = useState<IPollitem[]>([]);
  const [isShareButtonDisabled, setIsShareButtonDisabled] = useState<boolean>(true);
  const [tags, setTags] = useState<string[]>([]);
  const [isPollEndDateSelected, setIsPollEndDateSelected] = useState<boolean>(false);
  const [pollEndDate, setPollEndDate] = useState<Date | undefined>();
  const [feeling, setFeeling] = useState<EPostFeeling>(EPostFeeling.None);
  const [postImage, setPostImage] = useState<File | string>();
  const [previewImage, setPreviewImage] = useState<File>();
  const [existingPost, setExisitingPost] = useState<DBPost>();
  const [userCommunities, setUserCommunities] = useState<DBCommunity[]>([]);
  const [communityIds, setCommunityIds] = useState<string[]>(['everyone']);

  const [hasSavedPost, setHasSavedPost] = useState<boolean>(false);
  const [hasSavedPoll, setHasSavedPoll] = useState<boolean>(false);
  const [hasSavedImage, setHasSavedImage] = useState<boolean>(false);

  const [errors, setErrors] = useState<string[]>([]);

  const canRedirect = hasSavedPoll && hasSavedPost && hasSavedImage;

  const togglePollOption = () => setIsPollVisible(!isPollVisible);

  const addNewPollQuestion = () => {
    setPollItems([...pollItems, { id: uuid4(), title: '' }]);
  };

  const onPollTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setPollTitle(value);
  };

  const onPollItemChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, id },
    } = event;

    setPollItems(pollItems.map((post: IPollitem) => (String(post.id) === id ? { ...post, title: value } : post)));
  };

  const onRemovePollItem = (pollItemId: string) => {
    setPollItems(pollItems.filter(({ id }) => String(id) !== pollItemId));
  };

  const onPostCreateRequest = () => {
    setIsShareButtonDisabled(true);

    if (!isEmpty(existingPost)) {
      editPost({
        variables: {
          request: {
            id: existingPost.id,
            message: message.replaceAll('@', '{@}'),
            postType: EPostType.Feed,
            shareOption: existingPost.shareOption as EShareOption,
            tags,
          },
        },
      });

      if (!existingPost.poll && isPollVisible && hasMinimumPollData) {
        createPoll({
          variables: {
            postId: existingPost.id,
            items: pollItems.map(({ title }) => ({ title })),
            title: pollTitle,
            ...(isPollEndDateSelected && { end: pollEndDate }),
          },
        });

        return;
      }

      existingPost.poll
        ? updatePoll({
          variables: {
            postId: existingPost.id,
            items: pollItems.map(({ title }) => ({ title })),
            title: pollTitle,
            ...(isPollEndDateSelected && { end: pollEndDate }),
          },
        })
        : setHasSavedPoll(true);

      return;
    }

    createPostMutation({
      variables: {
        request: {
          message: message.replaceAll('@', '{@}'),
          postType: EPostType.Feed,
          shareOption: communityIds.includes('everyone') ? EShareOption.Everyone : EShareOption.Communities,
          feeling,
          ...(!communityIds.includes('everyone') && { communities: communityIds.map((id) => Number(id)) }),
        },
      },
    });
  };

  const onFeelingChange = (selectedFeeling: EPostFeeling) => {
    setFeeling(selectedFeeling);
    toggleFeelingsDialog();
  };

  const handleCreatePostCompleted = (response: CreatePostMutation) => {
    setHasSavedPost(true);
    const { post } = response;

    if(shouldTrackAnalytics()) {
      trackEvent(ESocialAnalyticEvents.SocialPostCreate, {
        postId: Number(post?.create?.id),
        hasImage: Boolean(postImage),
      });
    }

    if (!postImage) {
      setHasSavedImage(true);
    }

    if (postImage) {
      if (typeof postImage === 'string') return;

      executeCreatePostAsset({
        variables: {
          contentType: postImage.type,
          fileName: postImage.name,
          fileSizeInBytes: postImage.size,
          postId: Number(post?.create?.id),
          type: EAssetType.Image,
          alt: 'Post asset',
        },
      });
    }

    if (isPollVisible && hasMinimumPollData) {
      createPoll({
        variables: {
          postId: Number(post?.create?.id),
          items: pollItems.map(({ title }) => ({ title })),
          title: pollTitle,
          ...(isPollEndDateSelected && { end: pollEndDate }),
        },
      });

      return;
    }

    setHasSavedPoll(true);
  };

  const handleCreatePostError = () => {
    setIsShareButtonDisabled(false);
  };

  const handleCreatePollCompleted = () => {
    setHasSavedPoll(true);
  };

  const handleGetPostCompleted = (returnedPost: DBPost | undefined) => {
    if (!returnedPost) return;

    const isExisitingPostOwner = returnedPost.author.id === user?.id;

    if (!isExisitingPostOwner) return;

    setExisitingPost(returnedPost);
  };

  const handleCreatePostAssetCompleted = async (asset: CreatePostAssetMutation | undefined) => {
    const signedUrl = asset?.asset?.createPostAsset?.signedUrl;

    await fetch(signedUrl as string, {
      method: 'PUT',
      body: postImage,
    }).then(() => {
      setHasSavedImage(true);
    });
  };

  const handleGetUserCommunitiesCompleted = (communities: GetUserCommunitiesQueryResult[]) => {
    const returnedCommunities = communities.map(({ node }) => node)
    setUserCommunities(returnedCommunities.filter(({ status }) => status === ECommunityStatus.Published));
  };

  const handleUpdatePollCompleted = () => {
    setHasSavedPoll(true);
  };

  const handleEditPostCompleted = () => {
    if (!postImage) {
      setHasSavedImage(true);
    }

    setHasSavedPost(true);
  };

  const redirectToFeed = () => {
    push('/social/feed').catch((error) =>
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      }),
    );
  };

  const addErrorMessage = (error: string) => {
    if (!errors.includes(error)) {
      setErrors([...errors, error]);
    }
  };

  const removeErrorMessage = (error: string) => {
    if (errors.includes(error)) {
      setErrors(errors.filter((err) => err !== error));
    }
  };

  const { createPostMutation } = useCreatePost({
    onCompleted: handleCreatePostCompleted,
    onError: handleCreatePostError,
  });

  const { createPoll } = useCreatePoll({
    onCompleted: handleCreatePollCompleted,
  });

  const { getPost } = useGetPost({
    onCompleted: handleGetPostCompleted,
  });

  const { executeCreatePostAsset } = useCreatePostAsset({
    onCompleted: handleCreatePostAssetCompleted,
  });

  const { editPostMutation: editPost } = useEditPost({
    onCompleted: handleEditPostCompleted,
  });

  const { updatePoll } = useUpdatePoll({
    onCompleted: handleUpdatePollCompleted,
  });

  const { getUserCommunities } = useGetCommunitiesByUser({
    onCompleted: handleGetUserCommunitiesCompleted,
  });

  const { deleteAsset } = useDeleteAsset({
    onCompleted: () => {
      setPreviewImage(undefined);
      setPostImage(undefined);
      toggleDeleteAssetDialog();
    },
  });

  useEffect(() => {
    setTags(extractHashtags(message));
  }, [message]);

  useEffect(() => {
    if (!isEmpty(existingPost?.poll)) return;

    setPollItems(isPollVisible ? BASE_POLL_ITEMS : []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPollVisible]);

  useEffect(() => {
    if (!isEditModeRequested || isEmpty(user)) return;

    getPost({
      variables: {
        postId: Number(edit),
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModeRequested, user]);

  useEffect(() => {
    getUserCommunities({
      variables: {
        shouldIncludeCommunities: true,
        shouldIncludeProfileImage: true,
        shouldIncludeUserConnection: true,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasMinimumPollData = Boolean(pollTitle) && Boolean(pollItems) && Boolean(!pollItems.find(({ title }) => Boolean(!title)));

  useEffect(() => {
    setIsShareButtonDisabled((Boolean(!message) && !isPollVisible) || (isPollVisible && !hasMinimumPollData));
  }, [hasMinimumPollData, isPollVisible, message, pollItems, pollTitle]);

  useEffect(() => {
    if (isEmpty(existingPost)) return;

    setMessage(String(existingPost.message));
    setIsPollVisible(!isEmpty(existingPost.poll));
    setCommunityIds([String(existingPost.communityId)]);

    if (!isEmpty(existingPost.poll)) {
      setPollTitle(String(existingPost.poll.title));
      setPollItems(existingPost.poll.items.map(({ id, title }) => ({ title, id })) as IPollitem[]);
      setIsPollEndDateSelected(Boolean(existingPost.poll.end));
      setPollEndDate(existingPost.poll.end ? new Date(existingPost.poll.end) : new Date());
      setIsPollEndDateSelected(Boolean(existingPost.poll.end));

      if (existingPost.communityId) {
        setCommunityIds([String(existingPost.communityId)]);
      }
    }

    if (existingPost.assets?.length) {
      setPostImage(existingPost.assets[0].presignedUrl);
    }

    setFeeling(existingPost.feeling as EPostFeeling);
  }, [existingPost]);

  useEffect(() => {
    if (!isPollVisible) {
      removeErrorMessage(POLL_TITLE_ERROR_MESSAGE);
      removeErrorMessage(POLL_ITEM_MINIMUM_ERROR_MESSAGE);
      return;
    }

    pollTitle ? removeErrorMessage(POLL_TITLE_ERROR_MESSAGE) : addErrorMessage(POLL_TITLE_ERROR_MESSAGE);

    if (pollItems.length < 2) {
      addErrorMessage(POLL_ITEM_MINIMUM_ERROR_MESSAGE);
      setIsShareButtonDisabled(true);
    }

    if (pollItems.length >= 2 && Boolean(!pollItems.find(({ title }) => Boolean(!title)))) {
      removeErrorMessage(POLL_ITEM_MINIMUM_ERROR_MESSAGE);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPollVisible, pollItems, pollTitle]);

  useEffect(() => {
    if (canRedirect) {
      redirectToFeed();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canRedirect]);

  return (
    <BaseLayoutPage
      breadcrumbItems={[{ label: 'Create post' }]}
      meta={{ seoDescription: `Create a new post`, seoTitle: `Create post` }}
      title="Create post"
    >
      <div className="c-create-post-page__container">
        <h1>Create new post</h1>

        <PostFeeling feeling={feeling} />

        <PostMessageEditor
          value={message}
          onChange={(value) => {
            setMessage(value);
          }}
        />

        <div className="c-create-post-page__card h-spacing-large">
          <span className="c-create-post__label">Add to your post</span>

          <div className="flex items-center">
            <button
              className={getValidClassNames({
                'c-create-post__option': true,
                'c-create-post__option--active': Boolean(feeling) && feeling !== EPostFeeling.None,
              })}
              onClick={toggleFeelingsDialog}
            >
              <IconEmotion isActive={Boolean(feeling) && feeling !== EPostFeeling.None} />
              <span>Feeling</span>
            </button>
            <button
              className={getValidClassNames({
                'c-create-post__option': true,
                'c-create-post__option--active': isPollVisible,
              })}
              onClick={togglePollOption}
            >
              <IconPoll isActive={isPollVisible} />
              <span>Poll</span>
              <span className="h-hide-visually">{`${isPollVisible ? 'Remove' : 'Poll'}`}</span>
            </button>
            <button
              className={getValidClassNames({
                'c-create-post__option': true,
                'c-create-post__option--active': Boolean(postImage),
              })}
              onClick={toggleImageDialog}
            >
              <IconImage isActive={Boolean(postImage)} />
              <span>Image</span>
              <span className="h-hide-visually">{`${postImage ? 'Remove' : 'Add'} image`}</span>
            </button>
          </div>

          <PostFeelingsDialog
            isOpen={isFeelingsDialogOpen}
            selectedFeeling={feeling}
            onClose={toggleFeelingsDialog}
            onFeelingChange={onFeelingChange}
          />
        </div>

        {isPollVisible && (
          <>
            <h2>Create a poll</h2>
            <Input
              additionalClassNames="c-input--poll-title"
              id="poll-title"
              labelText="Poll title"
              name="poll-title"
              placeholder="Poll title"
              type="text"
              value={pollTitle}
              isLabelHidden
              onChange={onPollTitleChange}
            />
            {pollItems.map((question, key) => (
              <div key={key} className="c-poll-item">
                <Input
                  key={`poll-question-${key + 1}`}
                  id={`${String(question.id)}`}
                  labelText="Poll question"
                  name={`poll-question-${key + 1}`}
                  placeholder={`Poll question ${key + 1}`}
                  type="text"
                  value={question.title}
                  isLabelHidden
                  onChange={onPollItemChange}
                />
                <button onClick={() => onRemovePollItem(String(question.id))}>
                  <span className="h-hide-visually">Remove poll item</span>
                  <IconCross />
                </button>
              </div>
            ))}

            <Button
              additionalClassNames="h-spacing-large"
              iconId="id_plus_icon"
              text="Add new question"
              isIconOnly
              onClick={addNewPollQuestion}
            />

            <div className="c-create-post-date">
              <div className="c-create-post-date__toggle c-create-post-page__card">
                <span className="c-create-post__label">Set an end date</span>
                <ToggleSwitch
                  additionalClassNames="c-create-post-date__switch"
                  id="endDate"
                  isChecked={isPollEndDateSelected}
                  labelText="Set an end date"
                  isLabelHidden
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setIsPollEndDateSelected(event.target.checked)}
                />
              </div>

              <div className="c-create-post-page__card">
                <CalendarSnippet
                  id="calendar"
                  minDate={new Date().toISOString()}
                  selectedDates={[pollEndDate ?? new Date()]}
                  type="SINGLE_DATE"
                  onSingleDateChange={date => setPollEndDate(new Date(String(date)))}
                />
              </div>
            </div>
          </>
        )}

        {postImage && (
          <div className="c-create-post-page__card">
            <ImagePreview
              src={typeof postImage === 'string' ? postImage : URL.createObjectURL(postImage)}
              onClick={() => {
                if (existingPost?.assets) {
                  toggleDeleteAssetDialog();
                  return;
                }

                setPreviewImage(undefined);
                setPostImage(undefined);
              }}
            />
          </div>
        )}

        <Dialog
          isOpen={isImageDialogOpen}
          title="Select image"
          onClose={() => {
            toggleImageDialog();
            setPreviewImage(undefined);
          }}
          onConfirm={() => {
            setPostImage(previewImage);
            toggleImageDialog();
          }}
        >
          {!previewImage && (
            <DropzoneFileUpload
              labelText="Upload image for post"
              isLabelHidden
              onDrop={(acceptedFiles) => {
                setPreviewImage(acceptedFiles[0]);
              }}
            />
          )}

          {previewImage && <ImagePreview src={URL.createObjectURL(previewImage)} onClick={() => setPreviewImage(undefined)} />}
        </Dialog>

        {!isEditModeRequested && (
          <>
            <button
              className="c-create-post-share c-create-post-page__card"
              onClick={() => {
                toggleShareDialog();
              }}
            >
              <span>
                Share with:{' '}
                <strong>
                  {communityIds.includes('everyone')
                    ? 'everyone'
                    : userCommunities
                      .filter(({ id }) => communityIds.includes(String(id)))
                      .reduce((prev, next, key) => {
                        if (key === 0) return next.title;
                        return prev + `, ${next.title}`;
                      }, '')}
                </strong>
              </span>
            </button>
            <Dialog
              isOpen={isShareDialogOpen}
              title="Share with"
              onClose={toggleShareDialog}
              onConfirm={() => {
                if (communityIds.length === 0) {
                  setCommunityIds(['everyone']);
                }
                toggleShareDialog();
              }}
            >
              <CheckboxGroup
                items={[
                  { id: 'everyone', labelText: 'Everyone', value: 'everyone' },
                  ...userCommunities.map(({ id, title }) => ({ id: String(id), labelText: title, value: String(id) })),
                ]}
                legendText="Share with"
                name="shareOption"
                values={communityIds}
                isLabelHidden
                onChange={(values) => {
                  if (values[values.length - 1] === 'everyone') {
                    setCommunityIds(['everyone']);
                    return;
                  }

                  setCommunityIds(
                    values.length > 1 && values.includes('everyone') ? values.filter((value) => value !== 'everyone') : values,
                  );
                }}
              />
            </Dialog>
          </>
        )}

        {Boolean(errors.length) && (
          <div className="c-create-post__error">
            <IconWarning />
            <ul>
              {errors.map((error, key) => (
                <li key={key}>{error}</li>
              ))}
            </ul>
          </div>
        )}
        <div className="c-create-post-page__submit">
          <Button isDisabled={isShareButtonDisabled} text={existingPost ? 'Update' : 'Share'} onClick={onPostCreateRequest} />
        </div>
      </div>

      <Dialog
        isOpen={isDeleteAssetDialogOpen}
        title="Remove image"
        onClose={toggleDeleteAssetDialog}
        onConfirm={() => {
          if (!existingPost?.assets) return;

          deleteAsset({
            variables: {
              assetId: String(existingPost.assets[0].assetId),
            },
          });
        }}
      >
        <p>Are you sure you want to remove this image?</p>
      </Dialog>
    </BaseLayoutPage>
  );
};

export { CreatePostPage };
