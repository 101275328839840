import { useEffect, useState } from 'react';

import { Button, Dialog } from '@netfront/ui-library';
import { useRouter } from 'next/router';
import { parseHashTag } from 'utils';

import { DBCommunity, DBTagsByCommunity } from '../../../../interfaces';
import { EConnectionStatus, useGetCommunity, useGetTagsByCommunity } from '../../../../services';
import {
  BaseLayoutPage,
  CommunityMenu,
  EmptyMessage,
  IconLock,
  ListCard,
  ListCardSkeleton,
  OnlineModerators,
  PageBanner,
} from '../../../Social';

const CommunityHashtagsPage = () => {
  const {
    query: { communityKey },
  } = useRouter();

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState<boolean>(false);
  const [community, setCommunity] = useState<DBCommunity>();

  const [tags, setTags] = useState<DBTagsByCommunity[]>([]);

  const closeConnectDialog = () => {
    setIsConnectDialogOpen(false);
  };

  const onConnectRequest = () => {
    setIsConnected(true);
    closeConnectDialog();
  };

  const getCommunityCompleted = (returnedCommunity: DBCommunity) => {
    const { userConnection } = returnedCommunity;

    if (userConnection) {
      setIsConnected(userConnection.status === EConnectionStatus.Accepted);
    }

    setCommunity(returnedCommunity);
  };

  const { getCommunity } = useGetCommunity({
    onCompleted: getCommunityCompleted,
  });

  const handleGetTagsByCommunityCompleted = (tagsByCommunity: DBTagsByCommunity[]) => {
    setTags(tagsByCommunity);
  };

  const { getTagsByCommunity, isLoading: isGetCommunityTagsLoading } = useGetTagsByCommunity({
    onCompleted: handleGetTagsByCommunityCompleted,
  });

  useEffect(() => {
    if (community || !communityKey) {
      return;
    }

    getCommunity({ variables: { key: String(communityKey), shouldIncludeUserConnection: true } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityKey, isConnected]);

  useEffect(() => {
    if (!community) return;

    if (isConnected) {
      getTagsByCommunity({
        variables: {
          communityId: Number(community.id),
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community, isConnected]);

  return (
    <BaseLayoutPage
      breadcrumbItems={[{ label: 'Communities', href: '/social/communities' }, { label: community?.title ?? '' }]}
      meta={{ seoDescription: `View hashtags in ${String(community?.title)}`, seoTitle: `Hashtags in ${community?.title ?? ''}` }}
      title="Hashtags"
    >
      {community && (
        <>
          <PageBanner
            bannerImage={community.bannerImage?.presignedUrl}
            description={community.description}
            profileImage={community.profileImage?.presignedUrl}
            title={community.title}
          >
            {isConnected ? <CommunityMenu /> : <Button text="Connect" onClick={() => setIsConnectDialogOpen(true)} />}
          </PageBanner>

          <div className="c-community-hastags-page__container">
            {isConnected ? (
              <div>
                {isGetCommunityTagsLoading && [...Array(3)].map((_, key) => <ListCardSkeleton key={key} />)}

                {!isGetCommunityTagsLoading && Boolean(tags.length) && (
                  <>
                    <OnlineModerators />
                    {tags.map(({ count, key }) => (
                      <ListCard key={key} displayName={`${key}`} href={`/social/feed?hashtag=${parseHashTag(key)}`} shouldHideAvatar>
                        {count}
                      </ListCard>
                    ))}
                  </>
                )}
                {!isGetCommunityTagsLoading && Boolean(!tags.length) && <EmptyMessage message="No hashtags found" />}
              </div>
            ) : (
              <EmptyMessage icon={() => <IconLock />} message={`Connect to view ${String(community.title)}'s feed`} />
            )}
          </div>

          <Dialog
            isOpen={isConnectDialogOpen}
            title={`Connect with ${community.title}`}
            onCancel={closeConnectDialog}
            onClose={closeConnectDialog}
            onConfirm={onConnectRequest}
          >
            <p>Are you sure you want to connect with {community.title}?</p>
          </Dialog>
        </>
      )}
    </BaseLayoutPage>
  );
};

export { CommunityHashtagsPage };
