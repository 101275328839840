import { useEffect, useState } from 'react';

import { useToggle } from '@netfront/common-library';
import isEmpty from 'lodash.isempty';

import { HeaderMenuDesktop } from '../HeaderMenuDesktop';

import { LogoLink, MenuIcon, PrivateMobileMenu } from '../../../components';
import { useSocialContext } from '../../../contexts';
import { DBUserConnection } from '../../../interfaces';
import {
  ConversationsQueryResult,
  EConnectionStatus,
  useGetConversations,
  useGetUserConnections,
  UserConnectionQueryResult,
} from '../../../services';
import { NotificationsMenu, SocialHeaderProps, UserMenu } from '../../Social';

const SocialHeader = ({ children }: SocialHeaderProps) => {
  const { user, notifications } = useSocialContext();
  const { isToggled: isMobileMenuOpen, toggle: toggleMobileMenu } = useToggle();

  const [incomingRequests, setIncomingRequests] = useState<DBUserConnection[]>([]);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0);

  const handleGetConversationsCompleted = (conversations: ConversationsQueryResult[]) => {
    if (!conversations.length) {
      return;
    }

    setUnreadMessagesCount(conversations.map(({ node }) => Number(node.unReadCount)).reduce((prev, next) => prev + next));
  };

  const handleGetConnectionsCompleted = (connections: UserConnectionQueryResult[]) => {
    setIncomingRequests(
      connections
        .map(({ node }) => node)
        .filter(({ connectionStatus, friendId }) => connectionStatus === EConnectionStatus.Requested && friendId === user?.id),
    );
  };

  const { getConnections } = useGetUserConnections({
    onCompleted: handleGetConnectionsCompleted,
  });

  const { getConversations } = useGetConversations({
    onCompleted: handleGetConversationsCompleted,
  });

  useEffect(() => {
    getConnections();

    if (!isEmpty(user)) {
      return;
    }

    getConversations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <header className="c-social-header">
        <div className="c-social-header__container">
          <LogoLink href="/social/feed" />

          <div className="c-social-header__navigation">
            <HeaderMenuDesktop connectionsCount={incomingRequests.length} messageCount={unreadMessagesCount} />
          </div>

          <NotificationsMenu notifications={notifications.filter(({ seen }) => !seen)} />

          {user && (
            <>
              <div className="c-social-header__avatar">
                <UserMenu avatar={user.profileImage?.presignedUrl} displayName={user.displayedName} email={user.email} isOnline={user.isOnline} />
              </div>

              <button className="c-social-header__avatar--mobile" onClick={toggleMobileMenu}>
                <MenuIcon />
                <span className="h-hide-visually">{`${isMobileMenuOpen ? 'Close' : 'Open'} mobile menu`}</span>
              </button>
            </>
          )}
        </div>

        {isMobileMenuOpen && user && (
          <PrivateMobileMenu avatar={user.profileImage?.presignedUrl} displayName={user.displayedName} profileLink="/social/profile" />
        )}

        {children}
      </header>
    </>
  );
};

export { SocialHeader };
